import React from "react";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import CaseList1 from "../sections/studies/CaseList1";

const Partners = (props) => {
	return (
		<>
			<PageWrapper>
				<Seo
					title="Sharptree | Case Studies | Mobile Reimagined"
					description=""
					pathname={props.location.pathname}
				/>
				<PageHero title="Case Studies" />
				<CaseList1 />
			</PageWrapper>
		</>
	);
};
export default Partners;
