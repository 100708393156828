import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";

import breedonLogo from "../../assets/images/studies/breedon_logo.png";

const CaseList1 = () => {
	return (
		<>
			{/* <!-- Feature section --> */}
			<Section className="position-relative">
				<Container>
					<Row className="align-items-center justify-content-center">
						<Col lg="4" className="mb-5 mb-lg-0">
							<PostCard
								img={breedonLogo}
								slug="/studies/breedon"
								readMore
							>
								Breedon went from being introduced to Opqo to running
								a proof of concept in less than 1 month, including IT reviews and testing.
							</PostCard>
						</Col>
					</Row>
				</Container>
			</Section>
		</>
	);
};

export default CaseList1;
